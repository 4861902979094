<template>
  <v-row align="center" justify="end" no-gutters>
    <v-col cols="7" lg="3" md="4" sm="6">
      <v-expand-x-transition mode="in-out">
        <v-text-field v-if="iSearchBoxOpen" v-model="searchQuery" v-click-outside="searchBoxUnfocused"
                      :loading="isSearching" autofocus clearable color="white" dense hide-details>
        </v-text-field>
      </v-expand-x-transition>
    </v-col>
    <v-col cols="auto">
      <v-btn icon @click="closeSearchBox">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { SET_IS_SEARCH_BOX_OPEN, SET_SEARCH_QUERY } from '@/modules/app/store/mutation-types'

export default {
  name: 'KurccSearchBox',
  computed: {
    searchQuery: {
      get () {
        return this.$store.state.search.query
      },
      set (v) {
        const query = v != null ? v : ''
        this.$store.commit(SET_SEARCH_QUERY, query)
      }
    },
    isSearching () {
      return this.$store.state.search.isSearching
    },
    iSearchBoxOpen: {
      get () {
        return this.$store.state.search.isSearchBoxOpen
      },
      set (v) {
        this.$store.commit(SET_IS_SEARCH_BOX_OPEN, v)
      }
    }
  },
  methods: {
    searchBoxUnfocused () {
      if (this.iSearchBoxOpen && !this.searchQuery) {
        this.iSearchBoxOpen = false
      }
    },
    closeSearchBox () {
      this.searchQuery = null
      this.iSearchBoxOpen = !this.iSearchBoxOpen
    }
  }
}
</script>
